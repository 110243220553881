import { graphql } from "gatsby"
import React from "react"
import useProductPath from "../../../hooks/useProductPath"
import BackgroundMedia, { BackgroundMediaProps } from "../../molecules/BackgroundMedia"
import { HighlightBlockLayout } from "../../molecules/tileable-blocks/HighlightBlock"
import HighlightToProductBlock from "../../molecules/tileable-blocks/HighlightToProductBlock"
//import * as styles from "./DatoHighlightToProduct.module.scss"

export type DatoHighlightToProductProps = {
  text?: string
  title: string
  media?: Queries.DatoMediaFragment
  sku: string
  layout: HighlightBlockLayout
}

const DatoHighlightToProduct = (props: Queries.DatoHighlightToProductFragment) => {
  //console.log('Rendering DatoHighlightToProduct', props)
  const { sku, media, layout = "Tile", ...rest } = props
  const getProductPath = useProductPath()
  const url = getProductPath(sku) // linkki buildattuun tuotesivuun. TODO miten toimitaan jos tuotesivua ei buildattu?

  const backgrounImage = <BackgroundMedia {...(media as BackgroundMediaProps)} />

  return (
    <HighlightToProductBlock
      {...rest}
      layout={layout as HighlightBlockLayout}
      title={props.title ?? undefined}
      text={props.text ?? undefined}
      url={url}
      media={backgrounImage}
    />
  )
}

export default DatoHighlightToProduct

export const query = graphql`
  fragment DatoHighlightToProduct on DatoCmsHighlightToProduct {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    text
    title
    media {
      ...DatoMedia
    }
    sku
    layout
  }
`
