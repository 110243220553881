import React, { ReactElement } from "react"
import { slugify } from "../../../utils"
import { BackgroundMediaProps } from "../BackgroundMedia"
import HighlightBlock, { HighlightBlockLayout } from "./HighlightBlock"
//import * as styles from "./HighlightToProductBlock.module.scss"

type HighlightToProductBlockProps = {
  text?: string
  title?: string
  media?: ReactElement<BackgroundMediaProps>
  url?: string
  layout: HighlightBlockLayout
}

const HighlightToProductBlock = (props: HighlightToProductBlockProps) => {
  //console.log('Rendering HighlightToProductBlock', props)

  return (
    <HighlightBlock
      linkTo={props.url}
      id={slugify(props.title)}
      categoryTitle="Product"
      title={props.title}
      titleAs="h2"
      media={props.media}
      layout={props.layout}
      content={!!props.text && <p>{props.text}</p>}
    />
  )
}

export default HighlightToProductBlock
